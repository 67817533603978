var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "other-pages" },
    [
      _c(
        "v-container",
        { staticClass: "myorders-container white lighten-5" },
        [
          _c(
            "div",
            { staticClass: "my-3 mb-15 white lighten-5" },
            [
              _c("h2", { staticClass: "text-center hd-typ1 mb-6" }, [
                _vm._v(" MY ORDERS ")
              ]),
              _vm._l(_vm.orderDate, function(orderday, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "md-layout order-card-holder\n" },
                  _vm._l(_vm.orderList, function(order) {
                    return _c(
                      "div",
                      {
                        key: order.id,
                        staticClass: "md-layout-item order-card"
                      },
                      [
                        _c("div", { staticClass: "d-flex order-date" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .moment(order.created_at)
                                  .format("DD/MM/YYYY HH:mm")
                              ) +
                              " | "
                          ),
                          _c("div", { staticClass: "d-flex  orderId" }, [
                            order.status === "AcceptedByRestaurant"
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex orders" },
                                  [
                                    _c("v-img", {
                                      staticClass: "statusIcon ml-1 mr-1",
                                      attrs: {
                                        src: require("@/assets/images/icons/cooking.png"),
                                        alt: "info"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.isStoreDetails = true
                                        }
                                      }
                                    }),
                                    _c(
                                      "p",
                                      { staticClass: "statusText ma-auto" },
                                      [_vm._v(" Order Accepted ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            order.status === "ReadyToProcess"
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex orders" },
                                  [
                                    _c("v-img", {
                                      staticClass: "statusIcon ml-1 mr-1",
                                      attrs: {
                                        src: require("@/assets/images/icons/readytoprocess.png"),
                                        alt: "info"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.isStoreDetails = true
                                        }
                                      }
                                    }),
                                    _c(
                                      "p",
                                      { staticClass: "statusText ma-auto" },
                                      [_vm._v(" Ready to Process... ")]
                                    )
                                  ],
                                  1
                                )
                              : order.status === "Delivered"
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("v-img", {
                                      staticClass: "statusIcon ml-1 mr-1",
                                      attrs: {
                                        src: require("@/assets/images/icons/checkmark3.png"),
                                        alt: "info"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.isStoreDetails = true
                                        }
                                      }
                                    }),
                                    _c(
                                      "p",
                                      { staticClass: "statusText ma-auto" },
                                      [_vm._v(" Delivered ")]
                                    )
                                  ],
                                  1
                                )
                              : order.status === "Dispatched"
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("v-img", {
                                      staticClass: "statusIcon ml-1 mr-1",
                                      attrs: {
                                        src: require("@/assets/images/icons/checkmark3.png"),
                                        alt: "info"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.isStoreDetails = true
                                        }
                                      }
                                    }),
                                    _c(
                                      "p",
                                      { staticClass: "statusText ma-auto" },
                                      [_vm._v(" Ready ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ]),
                        _c("div", { staticClass: "orders" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center orders"
                            },
                            [
                              _c("div", { staticClass: "d-flex" }, [
                                _c("h4", [_vm._v("Bill #" + _vm._s(order.id))])
                              ]),
                              _c(
                                "div",
                                { staticClass: "price-detail text-right" },
                                [
                                  _c("div", { staticClass: "orderAmount" }, [
                                    _vm._v(
                                      " €" +
                                        _vm._s(order.amount.toFixed(2)) +
                                        " "
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        ]),
                        _vm._l(order.cart_items, function(item) {
                          return _c("div", { key: item.uuid }, [
                            _c("div", { staticClass: "pt-0 pb-1" }, [
                              _c("div", { staticClass: "orderName" }, [
                                _c("h4", [_vm._v(_vm._s(item.name))]),
                                _c(
                                  "div",
                                  { staticClass: "order-row" },
                                  _vm._l(item.menu_modifiers, function(
                                    modifierGroup
                                  ) {
                                    return _c(
                                      "span",
                                      { key: modifierGroup.id },
                                      [
                                        modifierGroup.selected_modifiers
                                          .length > 0
                                          ? _c(
                                              "span",
                                              { staticClass: "d-flex" },
                                              [
                                                _c("small", [
                                                  _vm._v(
                                                    _vm._s(modifierGroup.name) +
                                                      ":"
                                                  )
                                                ]),
                                                _vm._l(
                                                  modifierGroup.selected_modifiers,
                                                  function(modifier) {
                                                    return _c(
                                                      "span",
                                                      { key: modifier.id },
                                                      [
                                                        _c("small", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                modifier.name
                                                              ) +
                                                              ","
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ])
                            ])
                          ])
                        }),
                        order.status === "Delivered" ||
                        order.status === "AcceptedByRestaurant"
                          ? _c(
                              "div",
                              { staticClass: "d-flex orderOptions mt-7" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "rating d-flex" },
                                  [
                                    _vm._v(" Rate : "),
                                    _c("star-rating", {
                                      attrs: {
                                        "rounded-corners": true,
                                        "star-size": 21,
                                        "show-rating": false,
                                        "active-color": ["#ED7A00"],
                                        rating: order.rating,
                                        "read-only":
                                          order.rating >= 1 ? true : false
                                      },
                                      on: {
                                        "rating-selected": function($event) {
                                          return _vm.setRating($event, order.id)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex order-reorder" },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "ml-3",
                                        on: {
                                          click: function($event) {
                                            return _vm.downlodBill(order.id)
                                          }
                                        }
                                      },
                                      [_vm._v(" Download Bill ")]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "ml-3",
                                        on: {
                                          click: function($event) {
                                            return _vm.reOrder(order.id)
                                          }
                                        }
                                      },
                                      [_vm._v(" REORDER ")]
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  }),
                  0
                )
              })
            ],
            2
          ),
          _c("order-receipt", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ],
            attrs: { id: "receiptRef", "bill-details": _vm.orderList[0] }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }