<template>
  <div class="other-pages">
    <v-container class="myorders-container white lighten-5">
      <!-- <h2 class="text-center hd-typ1">
        My Orders
      </h2> -->

      <div class="my-3 mb-15 white lighten-5">
        <h2 class="text-center hd-typ1 mb-6">
          MY ORDERS
        </h2>

        <div
          v-for="(orderday, index) in orderDate"
          :key="index"
          class="md-layout order-card-holder
"
        >
          <!-- <div class="d-flex order-day mt-2 mb-6">
            {{ orderday }} |

            <div class="d-flex">
              <v-img
                src="@/assets/images/icons/cooking.png"
                alt="info"
                class="statusIcon ml-1 mr-1"
                @click="isStoreDetails = true"
              />
              <p class="statusText ma-auto">
                Preparing...
              </p>
            </div>
          </div> -->
          <div
            v-for="order in orderList"
            :key="order.id"
            class="md-layout-item order-card"
          >
            <div class="d-flex order-date">
              {{ moment(order.created_at).format('DD/MM/YYYY  HH:mm') }} |
              <div class="d-flex  orderId">
                <div
                  v-if="order.status === 'AcceptedByRestaurant'"
                  class="d-flex orders"
                >
                  <v-img
                    src="@/assets/images/icons/cooking.png"
                    alt="info"
                    class="statusIcon ml-1 mr-1"
                    @click="isStoreDetails = true"
                  />
                  <p class="statusText ma-auto">
                    Order Accepted
                  </p>
                </div>
                <div
                  v-if="order.status === 'ReadyToProcess'"
                  class="d-flex orders"
                >
                  <v-img
                    src="@/assets/images/icons/readytoprocess.png"
                    alt="info"
                    class="statusIcon ml-1 mr-1"
                    @click="isStoreDetails = true"
                  />
                  <p class="statusText ma-auto">
                    Ready to Process...
                  </p>
                </div>

                <div
                  v-else-if="order.status === 'Delivered'"
                  class="d-flex"
                >
                  <v-img
                    src="@/assets/images/icons/checkmark3.png"
                    alt="info"
                    class="statusIcon ml-1 mr-1"
                    @click="isStoreDetails = true"
                  />
                  <p class="statusText ma-auto">
                    Delivered
                  </p>
                </div>
                <div
                  v-else-if="order.status === 'Dispatched'"
                  class="d-flex"
                >
                  <v-img
                    src="@/assets/images/icons/checkmark3.png"
                    alt="info"
                    class="statusIcon ml-1 mr-1"
                    @click="isStoreDetails = true"
                  />
                  <p class="statusText ma-auto">
                    Ready
                  </p>
                </div>
              </div>
            </div>

            <!-- <v-row
              v-for="(item) in order.cart_items"
              :key="item.id"
            > -->
            <!-- <div class="my-4">
              <v-card
                elevation="0"
                class="order-box yourorder-box"
              >
                <v-card-text> -->
            <div class="orders">
              <div
                class="d-flex justify-content-between align-items-center orders"
              >
                <div class="d-flex">
                  <h4>Bill #{{ order.id }}</h4>
                </div>
                <div class="price-detail text-right">
                  <div class="orderAmount">
                    €{{ order.amount.toFixed(2) }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="item in order.cart_items"
              :key="item.uuid"
            >
              <div class="pt-0 pb-1">
                <!-- <div class="d-flex orderName mb-3">
                  <h4>{{ item.name }} | Bill {{ order.id }} | </h4>

                  <div class="d-flex">
                    <v-img
                      src="@/assets/images/icons/cooking.png"
                      alt="info"
                      class="statusIcon ml-1 mr-1"
                      @click="isStoreDetails = true"
                    />
                    <p class="statusText ma-auto">
                      Preparing...
                    </p>
                  </div>
                </div> -->
                <div class="orderName">
                  <h4>{{ item.name }}</h4>
                  <div class="order-row">
                    <span
                      v-for="modifierGroup in item.menu_modifiers"
                      :key="modifierGroup.id"
                    >
                      <span
                        v-if="modifierGroup.selected_modifiers.length > 0"
                        class="d-flex"
                      >
                        <small>{{ modifierGroup.name }}:</small>
                        <span
                          v-for="modifier in modifierGroup.selected_modifiers"
                          :key="modifier.id"
                        >
                          <small> {{ modifier.name }},</small>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                order.status === 'Delivered' ||
                  order.status === 'AcceptedByRestaurant'
              "
              class="d-flex orderOptions mt-7"
            >
              <div class="rating d-flex">
                Rate :
                <star-rating
                  :rounded-corners="true"
                  :star-size="21"
                  :show-rating="false"
                  :active-color="['#ED7A00']"
                  :rating="order.rating"
                  :read-only="order.rating >= 1 ? true : false"
                  @rating-selected="setRating($event, order.id)"
                />
              </div>
              <div class="d-flex order-reorder">
                <p
                  class="ml-3"
                  @click="downlodBill(order.id)"
                >
                  Download Bill
                </p>
                <p
                  class="ml-3"
                  @click="reOrder(order.id)"
                >
                  REORDER
                </p>
              </div>
            </div>

            <!-- <div class="line_segment4" /> -->

            <!-- </v-card-text>
              </v-card>
            </div> -->
            <!-- </v-row> -->
          </div>
        </div>
      </div>
      <order-receipt
        v-show="false"
        id="receiptRef"
        :bill-details="orderList[0]"
      />
    </v-container>
  </div>
</template>
<script>
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import StarRating from 'vue-star-rating'
import { useRouter } from '../utils/utils'
import OrderReceipt from '../components/OrderReceipt.vue'

export default {
  components: {
    StarRating,
    OrderReceipt,
  },

  setup() {
    const readOnly = ref(false)
    const orderList = ref([])
    const orderDate = ref([])
    const { router } = useRouter()
    const bill = ref({})

    const receiptRef = ref(null)

    const WithoutTime = dateTime => {
      const date = new Date(dateTime).toISOString().split('T')[0]
      return date
    }

    store.dispatch('cart/getOrders').then(res => {
      orderList.value = res

      orderDate.value = [
        ...new Set(orderList.value.map(item => WithoutTime(item.created_at))),
      ]
    })

    const grouped = computed(() => {
      const groups = {}
      orderList.value.forEach(item => {
        groups[WithoutTime(item.created_at)] = groups[WithoutTime(item.created_at)] || []
        groups[WithoutTime(item.created_at)].push(item)
      })
      return groups
    })

    const reOrder = id => {
      const data = orderList.value.find(el => el.id === id)
      store.state.cart.cartItems = data.cart_items
      localStorage.setItem('cart', JSON.stringify(data.cart_items))

      router.push({ name: 'cart' })
    }

    const downloadBill = () => {
      if (receiptRef.value) {
        receiptRef.value.downloadBill(123)
      }
    }

    const setRating = (rating, id) => {
      // eslint-disable-next-line no-self-assign, no-param-reassign
      rating = rating
      const body = { id, rating }
      store.dispatch('cart/rateOrder', body).then(() => {
        const orderItem = orderList.value.find(el => el.id === id)
        orderItem.rating = rating
      })
    }

    return {
      orderList,
      orderDate,
      grouped,
      reOrder,
      setRating,
      readOnly,
      bill,
      downloadBill,
    }
  },
  data() {
    return {
      rating: 0,
    }
  },
}
</script>
<style scoped>
.order-card {
  width: 45%;
  background-color: aliceblue;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  margin-bottom: 20px;
  box-shadow: 0px 6px 14px -9px #287dbb;
}
.order-card-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .order-card {
    width: 100% !important;
  }
}
</style>
